import { useState, useEffect, useMemo } from 'react';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Settings } from "@mui/icons-material";
import { format } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList, faRotate } from "@fortawesome/pro-regular-svg-icons";

import { ChipSet } from 'src/components/customize/chipset';
import { MultiSelect } from 'src/components/multi-select';
import { RouterLink } from 'src/components/router-link';
import { Scrollbar } from 'src/components/scrollbar';
import { SeverityPill } from 'src/components/severity-pill';
import { TableModal } from 'src/components/table-settings-modal';
import { TableNoData } from 'src/components/table-empty';
import { TableSkeleton } from 'src/components/table-skeleton';
import { paths } from 'src/paths';
import { useDebounce } from 'src/hooks/use-debounce';
import { dataEntryApi } from 'src/api/payment_audit/data-entry';
import { dataEntryMockedList } from '../../../../utils/constant/mock-data';

export const LandingDataEntryTable = () => {
  const localTableSetting = localStorage.getItem('tableSetting');
  const tableSetting = JSON.parse(localTableSetting);

  const [text, setText] = useState("");
  const [dataEntries, setDataEntries] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const query = useDebounce(text);
  const [enabled, setEnabled] = useState([]);

  const [rule, setRule] = useState([]);
  const [tableModal, setTableModal] = useState(false);

  const currentChip = useMemo(() => {
    const newChips = enabled?.map((item) => ({
      displayValue: item === "Valid" ? "Valid" : "Invalid",
      value: item,
      label: "Status"
    }));
    return newChips;
  }, [enabled]);


  const handleRemoveChip = (value) => {
    const newStatus = [...enabled].filter((item) => (item !== value));
    setEnabled(newStatus);
  };

  const getDataEntries = async () => {
    setIsLoading(true);
    let request = {
      page: currentPage + 1,
      per_page: perPage,
      q: query,
    }
    if (enabled[0] === 'Valid' && enabled?.length === 1) {
      request.status = 'Valid';
    }
    if (enabled[0] === 'Invalid' && enabled?.length === 1) {
      request.status = 'Invalid';
    }
    try {
      const res = await dataEntryApi.getDataEntries(request);
      setDataEntries(res?.files);
      setTotalCount(res?.total_count)
    } catch (error) {
      console.error('error: ', error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getDataEntries();
  }, [currentPage, perPage, query, enabled]);

  useEffect(() => {
    setRule(tableSetting?.dataEntryTable ?? []);
  }, [])

  const defaultColumn = [
    {
      id: 'id',
      label: 'Id',
      enabled: true,
      width: 50,
    },
    {
      id: 'name',
      label: 'Name',
      enabled: true,
      width: 100,
    },
    {
      id: 'status',
      label: 'Status',
      enabled: true,
      width: 100,
      render: (row) => (
        <SeverityPill color={row?.status === true ? 'success' : 'error'}>
          {row?.status === true ? "Valid" : "Invalid"}
        </SeverityPill>
      )
    },
    {
      id: 'invalid_records',
      label: 'Invalid Records',
      enabled: true,
      width: 100,
    },
    {
      id: 'updated_count',
      label: 'Updated Records',
      enabled: true,
      width: 100,
    },
    {
      id: 'total_records',
      label: 'Total Records',
      enabled: true,
      width: 100,
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      enabled: true,
      width: 150,
      render: (row) => (
        <Typography variant="body2" >
          {format(new Date(row?.updated_at), "yyyy-MM-dd HH:mm:ss")}
        </Typography>
      )
    },
  ];


  return (
    <>
      <Card>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ p: 2 }}
        >
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
          <Box sx={{ flexGrow: 1 }}>
            <Input
              disableUnderline
              fullWidth
              placeholder="Enter a keyword"
            />
          </Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title="Reload Table">
              <IconButton>
                <FontAwesomeIcon icon={faRotate} width={22} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Search Setting">
              <IconButton>
                <FontAwesomeIcon icon={faFilterList} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Table Setting">
              <IconButton>
                <Settings />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Scrollbar>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow sx={{ whiteSpace: 'nowrap' }}>
                {defaultColumn?.filter((item) => item.enabled)?.map((item) => (
                  <TableCell key={item.id}
                    sx={{ width: item.width }}
                  >
                    <Typography
                      sx={{ fontSize: 14, fontWeight: '600' }}
                    >{item.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(dataEntryMockedList?.slice(currentPage * perPage, (currentPage * perPage) + perPage)?.map((brand) => (
                <TableRow key={brand?.id}
                  sx={{ whiteSpace: 'nowrap' }}>
                  {defaultColumn?.filter((item) => item.enabled)?.map((column, index) => (
                    <TableCell key={brand.id + index}>
                      {column?.render ? column?.render(brand) : brand[column?.id]}
                    </TableCell>
                  ))}
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePagination
          component="div"
          count={dataEntryMockedList.length ?? 0}
          onPageChange={(event, index) => setCurrentPage(index)}
          onRowsPerPageChange={(event) => setPerPage(event?.target?.value)}
          page={currentPage}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
        />
      </Card>
    </>
  );
};
