import { useCallback, useEffect, useState } from "react";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { usePopover } from "src/hooks/use-popover";
import { Box, IconButton, OutlinedInput, TextField } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Scrollbar } from "../scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

export const CustomFilterText = ({
  label,
  placeholder,
  field = {},
  onSetField = () => { },
}) => {
  const popover = usePopover();
  const [text, setText] = useState("");

  const handleUpdateFilter = () => {
    onSetField(prev => {
      return prev?.map(item => {
        if (item?.custom_id === field?.id) {
          return {
            ...item,
            filter: {
              field_id: field?.id,
              field_type: field?.field_type,
              query: text
            },
          }
        } else {
          return item;
        }
      });
    });
    popover.handleClose();
    setText("");
  }

  return (
    <>
      <Button
        color="inherit"
        endIcon={
          <SvgIcon>
            <ChevronDownIcon />
          </SvgIcon>
        }
        sx={{ p: 0 }}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        <Typography fontSize={14} fontWeight="600" sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </Typography>
      </Button>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ style: { px: 2 } }}
      >
        <Stack sx={{ p: 2 }} direction="row" alignItems="center" spacing={2}>
          <OutlinedInput placeholder={placeholder} value={text} onChange={(e) => {
            e.preventDefault();
            setText(e?.target?.value);
          }} />

          <IconButton onClick={handleUpdateFilter} color="primary">
            <FontAwesomeIcon icon={faCheck} />
          </IconButton>
        </Stack>
      </Menu>
    </>
  );
};
