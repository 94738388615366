import { useEffect, useState, useMemo } from "react";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { Settings } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

import { paths } from "src/paths";
import LandingCustomerBalance from "./customer-balance";
import { Scrollbar } from "src/components/scrollbar";
import { SeverityPill } from "src/components/severity-pill";
import { TableNoData } from "src/components/table-empty";
import { TableSkeleton } from "src/components/table-skeleton";
import { customersApi } from "src/api/customers";
import { useAuth } from "src/hooks/use-auth";
import { authApi } from "src/api/auth";
import { userApi } from "src/api/user";
import { LandingCustomerPositionsTable } from "./customer-positions-table";

const directions = {
  0: "OWNED",
  1: "BUY",
  2: "SELL",
};

const statuses = {
  0: "Pending",
  1: "Active",
  2: "Closed",
};

const currencies = {
  1: "$",
  2: "€",
  3: "£",
  4: "CA$",
  5: "A$",
};

const market = {
  fx: "Forex",
  commodities: "Commodities",
  crypto: "Crypto",
  stocks: "Stocks",
  cfd: "CFD",
};

export const LandingCustomerPosition = () => {
  return (
    <>
      <LandingCustomerBalance />
      <LandingCustomerPositionsTable />
    </>
  );
};
