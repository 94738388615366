import { createResourceId } from "src/utils/create-resource-id";
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from "src/utils/jwt";
import { wait } from "src/utils/wait";
import { users } from "./data";
import apiClient from "../../utils/request";

const STORAGE_KEY = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = () => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user) => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

class AuthApi {
  async getCompanies(request) {
    const response = await apiClient.post("/user/session", request);

    return response.companies;
  }

  async signIn(request) {
    const { data } = request;
  }

  async signUp(request) {
    const { email, name } = request;

    const response = await apiClient.post("/user/users", {
      email,
      company_name: name,
    });
  }

  async me(request) {
    const { accountId } = request;

    const { account, company } = await apiClient.get(
      `/account/info/${accountId}`
    );

    return {
      account,
      company,
    };
  }

  async verifyEmail(request = {}) {
    const response = await apiClient.put("/account/invitation", request);
    return response;
  }

  async sendRecoveryEmail(request = {}) {
    await apiClient.post("/user/password_recovery", request);
  }

  async recoverPassword(request = {}, token = "") {
    await apiClient.put("/user/password", request, {
      headers: {
        Authorization: token,
      },
    });
  }

  async updatePassword(request = {}) {
    await apiClient.put("/account/password", request);
  }

  async logoutAllSessions() {
    await apiClient.delete("/user/session", {
      params: { logout_all: true },
    });
  }

  async getCompanyId(params = {}) {
    const response = await apiClient.get("/company/token", { params });
    return response;
  }
}

export const authApi = new AuthApi();
