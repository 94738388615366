import { useMemo } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { format } from "date-fns";

export const LandingCustomerPosts = ({ customer }) => {
  const commentMockedList = useMemo(() => {
    if (customer) {
      return [
        {
          id: 1,
          account_name: "David Brown",
          created_at: new Date(),
          comment: "Customer Relationship Management (CRM) systems offer numerous benefits for businesses. From streamlining communication to improving customer satisfaction, implementing a CRM solution can transform the way you manage customer interactions. In this post, we explore the key advantages of adopting CRM and how it can positively impact your business operations.",
        },
        {
          id: 2,
          account_name: "William Taylor",
          created_at: new Date(),
          comment: "Selecting the right CRM software is crucial for the success of your business. With various options available, finding a solution that aligns with your unique needs is essential. This post provides a comprehensive guide to help you navigate the process of choosing the right CRM software. Learn about key features, considerations, and best practices in CRM software selection.",
        },
        {
          id: 3,
          account_name: "Olivia Martin",
          created_at: new Date(),
          comment: "CRM isn't just a tool for managing customer relationships; it's also a powerful asset for boosting sales. This post delves into effective strategies for maximizing sales through CRM. From lead management to customer segmentation, discover how CRM can enhance your sales processes and contribute to the overall growth of your business.",
        },
        {
          id: 4,
          account_name: "Henry Turner",
          created_at: new Date(),
          comment: "As technology continues to evolve, so does the landscape of Customer Relationship Management. In this post, we explore emerging trends in CRM that are shaping the future of customer engagement. From artificial intelligence to personalized customer experiences, stay informed about the latest developments and position your business for success in the evolving CRM landscape.",
        },
      ]
    }
  }, [customer]);


  const steps = useMemo(
    () =>
      commentMockedList?.map((comment) => {
        return {
          labelRender: () => {
            return (
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ px: 1 }}>
                  {comment.account_name}
                </Typography>
                <Typography sx={{ px: 1 }}>
                  {format(new Date(comment?.created_at), "dd MMM yyyy HH:mm")}
                </Typography>
              </Stack>
            );
          },
          contentRender: () => (
            <Stack
              direction={"row"}
              alignItems={"start"}
              spacing={2}
              py={1}
              justifyContent={"space-between"}
              key={comment?.id}
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: comment?.comment?.replace(/\n/g, "<br>"),
                }}
              />
              <Stack direction="row" gap={1} sx={{ minWidth: 150 }} justifyContent="flex-end">
                <IconButton
                  size="small"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </IconButton>
                <IconButton
                  size="small"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Stack>
            </Stack>
          ),
        };
      }),
    [commentMockedList]
  );

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h6"}>Posts</Typography>
            <Button variant={"contained"} onClick={() => setModalOpen(true)}>
              + Add
            </Button>
          </Stack>
        }
      />
      <CardContent>
        {(
          <Stepper orientation="vertical">
            {steps
              ?.filter((s) => s)
              ?.map((step, index) => (
                <Step active key={index}>
                  <StepLabel>{step?.labelRender()}</StepLabel>
                  <StepContent>{step?.contentRender()}</StepContent>
                </Step>
              ))}
          </Stepper>
        )}
      </CardContent>
    </Card>
  );
};
