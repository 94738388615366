import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { Logo } from 'src/components/logos/logo';
import { RouterLink } from 'src/components/router-link';
import { getAPIUrl } from 'src/config';
import { paths } from 'src/paths';
import { useSettings } from "src/hooks/use-settings";

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundImage: 'url("/assets/others/gradient-bg.svg")',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%'
}));

export const Layout = (props) => {
  const { children } = props;
  const avatar = useSelector(state => state.companies.avatar);
  const settings = useSettings();


  return (
    <LayoutRoot>
      <Box
        component="header"
        sx={{
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        {/* <Container maxWidth="lg">
          <Stack
            direction="row"
            spacing={2}
            sx={{ height: TOP_NAV_HEIGHT }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              {avatar ?
                <img
                  src={avatar ? `${getAPIUrl()}/${avatar}` : ""}
                  alt="Company logo"
                  loading="lazy"
                  style={{ height: 24, width: 24, objectFit: "fill", backgroundColor: "9BA4B5" }}
                />
                :
                <SvgIcon sx={{ height: 24, width: 24 }}>
                  <Logo color={settings?.colorPreset} />
                </SvgIcon>}
              <Box
                sx={{
                  color: 'text.primary',
                  fontFamily: '\'Plus Jakarta Sans\', sans-serif',
                  fontSize: 14,
                  fontWeight: 800,
                  letterSpacing: '0.3px',
                  lineHeight: 2.5,
                  '& span': {
                    color: 'primary.main'
                  }
                }}
              >
                OCTOLIT
              </Box>
            </Stack>
          </Stack>
        </Container> */}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flex: '1 1 auto'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: '60px',
              md: '120px'
            }
          }}
        >
          {children}
        </Container>
      </Box>
    </LayoutRoot>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
