import { Dialog, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";

import { Scrollbar } from "../../../../components/scrollbar";
import { LeadBulkStep } from "./bulk-step";

export const BulkActionModal = ({ open, onClose, selectedLeads, selectAll, getStatusInfo, onDeSelectAll }) => {

  return (
    <Dialog open={open}
      onClose={onClose}
      fullWidth>
      <Scrollbar sx={{ maxHeight: 750 }}>
        <Container maxWidth="sm"
          sx={{ p: 3 }}>
          <Stack direction="column"
            spacing={3}
            pb={3}>
            <Stack
              py={2}
              direction='row'
              justifyContent='center'>
              <Typography variant="h5">Create Leads</Typography>
            </Stack>
            <Stack>
              <LeadBulkStep
                onDeSelectAll={onDeSelectAll}
                getStatusInfo={getStatusInfo}
                selectedLeads={selectedLeads}
                selectAll={selectAll}
                onClose={onClose}
              />
            </Stack>
          </Stack>
        </Container>
      </Scrollbar>

    </Dialog>
  );
};
