import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";

import { countries } from "src/utils/constant";
import { OfferItem } from "./offer-item";
import { offersMockedList } from "src/utils/constant/mock-data";

export const LandingOffersTable = (props) => {
  const [country, setCountry] = useState(null);

  return (
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 5 }}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
            alignItems="center"
            spacing={3}
          >
            <Box sx={{ width: "400px" }}>
              <Autocomplete
                options={countries}
                autoHighlight
                value={countries?.find((item) => item?.code === country)}
                onChange={(event, value) => setCountry(value?.code)}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select country"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Box>
            <OutlinedInput
              placeholder="Search"
              sx={{ width: 400 }}
            />
          </Stack>
          <Box>
            <Button
              startIcon={
                <SvgIcon>
                  <PlusIcon />
                </SvgIcon>
              }
              variant="contained"
            >
              Add
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          {offersMockedList?.map((offer) => (
            <OfferItem offer={offer} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};
