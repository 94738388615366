import { useCallback, useEffect, useState } from "react";
import { Button, Dialog, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Container, Stack } from "@mui/system";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from 'date-fns';
import { LoadingButton } from "@mui/lab";

export const EditReminderModal = (props) => {
  const { open, onClose, onUpdateReminder, reminder = {}, isLoading = false } = props;

  const [description, setDescription] = useState(null);

  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (reminder) {
      setDescription(reminder.description);
      setDate(new Date(reminder?.time?.split(".")[0]));
      setTime(new Date(reminder?.time?.split(".")[0]));
    }
  }, [reminder]);

  const handleUpdate = useCallback(() => {
    const newTime = `${format(date, 'yyyy-MM-dd')} ${format(time, 'HH:mm')}`
    onUpdateReminder({ time: newTime, description });
  }, [time, date, description]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth>
      <Container
        maxWidth="sm"
        sx={{ p: 3 }}>
        <Stack
          direction="column"
          spacing={3}>
          <Stack
            py={2}
            direction="row"
            justifyContent="center">
            <Typography
              variant="h5">Update Reminder</Typography>
          </Stack>
          <Stack>
            <Stack
              spacing={2}>
              <Grid
                container
                spacing={2}>
                <Grid
                  xs={6}>
                  <Stack
                    spacing={1}>
                    <Typography
                      variant="h7"
                      px={1}>
                      Date
                    </Typography>
                    <DatePicker
                      format="yyyy-MM-dd"
                      label="Reminder Time"
                      value={date ? new Date(date) : new Date()}
                      onChange={(val) => setDate(val)}
                    />
                  </Stack>
                </Grid>
                <Grid
                  xs={6}>
                  <Stack
                    spacing={1}>
                    <Typography
                      variant="h7"
                      px={1}>
                      Time
                    </Typography>
                    <TimePicker
                      format="h:mm a"
                      label="Reminder Time"
                      value={time ? new Date(time) : new Date()}
                      onChange={(val) => setTime(val)}
                    />
                  </Stack>
                </Grid>
                <Grid
                  xs={12}>
                  <Stack
                    spacing={1}>
                    <Typography
                      variant="h7"
                      px={1}>
                      Description
                    </Typography>
                    <TextField
                      label="Description"
                      value={description}
                      onChange={(event) => setDescription(event?.target?.value)}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  py: 2,
                  px: 3,
                }}
                gap={3}
              >
                <LoadingButton variant="contained"
                  onClick={handleUpdate}
                  loading={isLoading}
                  disabled={!time}>
                  Update
                </LoadingButton>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Dialog>
  );
};
