import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";

export const TableNoData = ({ isSmall, label }) => {
  return (
    <>
      <Box
        sx={{
          py: isSmall ? 5 : 8,
          maxWidth: 1,
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src="/assets/errors/error-404.png"
          sx={{
            height: "auto",
            maxWidth: 120,
          }}
        />
        <Typography color="text.secondary" sx={{ mt: 2 }} variant="subtitle1">
          {label ?? "No Data."}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};
