import { useCallback, useState } from "react";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Lock01Icon from "@untitled-ui/icons-react/build/esm/Lock01";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";
import { CheckoutSummary } from "src/sections/checkout/checkout-summary";
import { TextField } from "@mui/material";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  new_password: Yup.string().required("New password is required"),
  workspace_name: Yup.string().matches(
    /^[a-zA-Z]+$/,
    "Must be a string without spaces and symbols"
  ),
});

const initialProducts = [
  {
    id: "97375399bf10f57d0f0f7fd9",
    image: "/assets/products/product-1.png",
    name: "Dedicated server",
    quantity: 1,
  },
  {
    id: "ece4069546ff025047b97735",
    image: "/assets/products/product-2.png",
    name: "OCTOLIT .link",
    price: 95.0,
    quantity: 1,
  },
];

const Page = () => {
  usePageView();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback((data) => {
  }, []);

  return (
    <>
      <Seo title="Checkout " />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  href={paths.index}
                  sx={{
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">Home</Typography>
                </Link>
              </div>
              <Typography variant="h3">Checkout</Typography>
            </Stack>
            <Box mt={6}>
              <Grid container spacing={6}>
                <Grid md={7} xs={12}>
                  <Stack spacing={6}>
                    <Stack spacing={3}>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <Box
                          sx={{
                            alignItems: "center",
                            border: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                            borderRadius: 20,
                            display: "flex",
                            height: 40,
                            justifyContent: "center",
                            width: 40,
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "fontWeightBold" }}
                            variant="h6"
                          >
                            1
                          </Typography>
                        </Box>
                        <Typography variant="h6">Information</Typography>
                      </Stack>
                      <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Grid container spacing={3}>
                            <Grid xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                error={!!errors?.email?.message}
                                helperText={errors?.email?.message}
                                {...register("email")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="New Password"
                                name="new_password"
                                error={!!errors?.new_password?.message}
                                helperText={errors?.new_password?.message}
                                {...register("new_password")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Workspace"
                                name="workspace_name"
                                error={!!errors?.workspace_name?.message}
                                helperText={errors?.workspace_name?.message}
                                {...register("workspace_name")}
                              />
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid md={5} xs={12}>
                  <CheckoutSummary
                    onQuantityChange={() => { }}
                    products={initialProducts}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <SvgIcon sx={{ color: "success.main" }}>
                  <Lock01Icon />
                </SvgIcon>
                <Typography variant="subtitle2">Secure Checkout</Typography>
              </Stack>
              <Typography color="text.secondary" sx={{ mt: 2 }} variant="body2">
                Your purchases are secured by an industry best encryption service
                – Braintree
              </Typography>
              <Button
                color="primary"
                endIcon={
                  <SvgIcon>
                    <ArrowRightIcon />
                  </SvgIcon>
                }
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Complete order
              </Button>
            </Box>
          </Container>
        </form>
      </Box>
    </>
  );
};

export default Page;
