import PropTypes from 'prop-types';
import numeral from 'numeral';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export const CheckoutSummary = (props) => {
  const { products = [], ...other } = props;

  return (
    <Card
      variant="outlined"
      sx={{ p: 3 }}
      {...other}>
      <Typography variant="h6">
        Order Summary
      </Typography>
      <List sx={{ mt: 2 }}>
        {products.map((product) => {
          return (
            <ListItem
              disableGutters
              key={product.id}
            >
              <ListItemText
                primary={(
                  <Typography
                    sx={{ fontWeight: 'fontWeightBold' }}
                    variant="subtitle1"
                  >
                    {product.name}
                  </Typography>
                )}
              />
              <ListItemSecondaryAction>
                1
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle2">
          Total
        </Typography>
        <Typography variant="subtitle2">
          $ 700
        </Typography>
      </Box>
    </Card>
  );
};

CheckoutSummary.propTypes = {
  onQuantityChange: PropTypes.func,
  products: PropTypes.array
};
