import {
  Avatar,
  AvatarGroup,
  Card,
  CardContent,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import PropTypes from "prop-types";

import PauseIcon from "@untitled-ui/icons-react/build/esm/PhonePause";
import WhisperIcon from "@untitled-ui/icons-react/build/esm/Signal02";
import MuteIcon from "@untitled-ui/icons-react/build/esm/MicrophoneOff01";
import HangupIcon from "@untitled-ui/icons-react/build/esm/PhoneHangUp";
import LeaveCallIcon from "@untitled-ui/icons-react/build/esm/UserLeft01";
import { useCallback, useState } from "react";
import { chatApi } from "src/api/chat";
import { useTwilio } from "src/hooks/use-twilio";
import CountUp from "./count-up";
import { RouterLink } from "./router-link";
import { paths } from "src/paths";
import { getAPIUrl } from "src/config";

export const CallPopup = (props) => {
  const {
    conversationId,
    ticketId,
    customerId,
    chatToken,
    participants = [],
  } = props;
  const [muted, setMuted] = useState(false);
  const [whisper, setWhisper] = useState(false);
  const [paused, setPaused] = useState(false);

  const { muteExternal, muteInternal } = useTwilio();

  const handleCallHangUp = useCallback(async () => {
    await chatApi.callControl({
      end_call: true,
      conversation_id: conversationId,
    });
  }, [conversationId]);

  const handleMuteControl = useCallback(async () => {
    await chatApi.callControl({
      mute: !muted,
      conversation_id: conversationId,
    });

    if (muted) {
      if (!whisper) {
        muteExternal(false);
      }
      muteInternal(false);
    } else {
      muteExternal(true);
      muteInternal(true);
    }

    setMuted(!muted);
  }, [conversationId, muted, muteExternal, muteInternal, whisper]);

  const handleWhisperControl = useCallback(async () => {
    await chatApi.callControl({
      whisper: !whisper,
      conversation_id: conversationId,
    });

    if (!whisper) muteExternal(true);
    setWhisper(!whisper);
  }, [conversationId, muteExternal, whisper]);

  const handleLeaveCall = useCallback(async () => {
    await chatApi.callControl({ leave: true, conversation_id: conversationId });
  }, [conversationId]);

  return (
    <Card
      elevation={16}
      sx={{
        position: "fixed",
        top: 67,
        left: "40%",
        width: 600,
        zIndex: 10000,
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column" spacing={1} alignItems="flex-start">
            {participants.length ? (
              <AvatarGroup>
                {participants.map((participant) => (
                  <Tooltip
                    title={`${participant?.first_name} ${participant?.last_name}`}
                  >
                    <Stack direction="column" alignItems="flex-start">
                      <Avatar
                        src={participant.avatar ? participant.avatar?.includes('http') ? participant.avatar : `${getAPIUrl()}/${participant.avatar}` : ""}
                      />
                      <Stack direction="row" spacing={1}>
                        {participant.mute && (
                          <SvgIcon sx={{ height: 20, color: "red" }}>
                            <MuteIcon />
                          </SvgIcon>
                        )}
                        {participant.whisper && (
                          <SvgIcon sx={{ height: 20, color: "red" }}>
                            <WhisperIcon />
                          </SvgIcon>
                        )}
                      </Stack>
                    </Stack>
                  </Tooltip>
                ))}
              </AvatarGroup>
            ) : null}

            <Typography
              component={RouterLink}
              href={
                paths.dashboard.customers.index +
                `/${customerId}` +
                `?conversationId=${conversationId}` +
                `&ticketId=${ticketId}` +
                `&token=${chatToken}` +
                `&tab=chat`
              }
            >
              Open conversation
            </Typography>
          </Stack>
          <CountUp />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title="Pause">
              <IconButton sx={{ color: paused ? "red" : "" }}>
                <SvgIcon>
                  <PauseIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Whisper">
              <IconButton
                onClick={handleWhisperControl}
                sx={{ color: whisper ? "red" : "" }}
              >
                <SvgIcon>
                  <WhisperIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Mute">
              <IconButton
                onClick={handleMuteControl}
                sx={{ color: muted ? "red" : "" }}
              >
                <SvgIcon>
                  <MuteIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Hang up">
              <IconButton sx={{ color: "red" }} onClick={handleCallHangUp}>
                <SvgIcon>
                  <HangupIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Leave call">
              <IconButton sx={{ color: "red" }} onClick={handleLeaveCall}>
                <SvgIcon>
                  <LeaveCallIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

CallPopup.propTypes = {
  conversationId: PropTypes.string.isRequired,
  participants: PropTypes.array,
  chatToken: PropTypes.string,
  ticketId: PropTypes.string,
  customerId: PropTypes.string,
};
