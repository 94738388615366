export const envByDomains = [
  {
    domain: "localhost" ?? "",
    api_url: process.env.REACT_APP_HOST ?? "",
    socket_url: process.env.REACT_APP_SOCKET ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN ?? "",
    api_url: process.env.REACT_APP_HOST ?? "",
    socket_url: process.env.REACT_APP_SOCKET ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN2 ?? "",
    api_url: process.env.REACT_APP_HOST2 ?? "",
    socket_url: process.env.REACT_APP_SOCKET2 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN3 ?? "",
    api_url: process.env.REACT_APP_HOST3 ?? "",
    socket_url: process.env.REACT_APP_SOCKET3 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN4 ?? "",
    api_url: process.env.REACT_APP_HOST4 ?? "",
    socket_url: process.env.REACT_APP_SOCKET4 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN5 ?? "",
    api_url: process.env.REACT_APP_HOST5 ?? "",
    socket_url: process.env.REACT_APP_SOCKET5 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN6 ?? "",
    api_url: process.env.REACT_APP_HOST6 ?? "",
    socket_url: process.env.REACT_APP_SOCKET6 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN7 ?? "",
    api_url: process.env.REACT_APP_HOST7 ?? "",
    socket_url: process.env.REACT_APP_SOCKET7 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN8 ?? "",
    api_url: process.env.REACT_APP_HOST8 ?? "",
    socket_url: process.env.REACT_APP_SOCKET8 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN9 ?? "",
    api_url: process.env.REACT_APP_HOST9 ?? "",
    socket_url: process.env.REACT_APP_SOCKET9 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN10 ?? "",
    api_url: process.env.REACT_APP_HOST10 ?? "",
    socket_url: process.env.REACT_APP_SOCKET10 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN11 ?? "",
    api_url: process.env.REACT_APP_HOST11 ?? "",
    socket_url: process.env.REACT_APP_SOCKET11 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN12 ?? "",
    api_url: process.env.REACT_APP_HOST12 ?? "",
    socket_url: process.env.REACT_APP_SOCKET12 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN13 ?? "",
    api_url: process.env.REACT_APP_HOST13 ?? "",
    socket_url: process.env.REACT_APP_SOCKET13 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN14 ?? "",
    api_url: process.env.REACT_APP_HOST14 ?? "",
    socket_url: process.env.REACT_APP_SOCKET14 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN15 ?? "",
    api_url: process.env.REACT_APP_HOST15 ?? "",
    socket_url: process.env.REACT_APP_SOCKET15 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN16 ?? "",
    api_url: process.env.REACT_APP_HOST16 ?? "",
    socket_url: process.env.REACT_APP_SOCKET16 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN17 ?? "",
    api_url: process.env.REACT_APP_HOST17 ?? "",
    socket_url: process.env.REACT_APP_SOCKET17 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN18 ?? "",
    api_url: process.env.REACT_APP_HOST18 ?? "",
    socket_url: process.env.REACT_APP_SOCKET18 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN19 ?? "",
    api_url: process.env.REACT_APP_HOST19 ?? "",
    socket_url: process.env.REACT_APP_SOCKET19 ?? ""
  },
  {
    domain: process.env.REACT_APP_HOST_DOMAIN20 ?? "",
    api_url: process.env.REACT_APP_HOST20 ?? "",
    socket_url: process.env.REACT_APP_SOCKET20 ?? ""
  },
];

export const getBaseApiUrl = () => {
  const currentDomain = window?.location?.hostname;
  const currentApiUrl = envByDomains?.find((item) => item?.domain === currentDomain)?.api_url ?? process.env.REACT_APP_HOST;
  return currentApiUrl;
};

export const getAPIUrl = () => {
  const currentDomain = window?.location?.hostname;
  const currentApiUrl = envByDomains?.find((item) => item?.domain === currentDomain)?.api_url ?? process.env.REACT_APP_HOST;
  return currentApiUrl?.replace(/\/api\/?$/, '');
};

export const getBaseSocketUrl = () => {
  const currentDomain = window?.location?.hostname;
  const currentSocketUrl = envByDomains?.find((item) => item?.domain === currentDomain)?.socket_url ?? process.env.REACT_APP_SOCKET;
  return currentSocketUrl;
};

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  base_url: process.env.REACT_APP_AUTH0_BASE_URL,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  issuer_base_url: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const mapboxConfig = {
  apiKey: process.env.REACT_APP_MAPBOX_API_KEY
};
