import React from 'react'
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';

const CustomSwitch = ({ label, control, name }) => {

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = false } }) => (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          {label && <Typography
            variant='subtitle2'>
            {label}
          </Typography>}
          <Switch checked={value ?? false}
            onChange={(event) => onChange(event?.target?.checked)} />
        </Stack>
      )} />
  )
}

export default CustomSwitch