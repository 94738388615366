import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getBaseApiUrl } from 'src/config';

const apiClient = axios.create({
  baseURL: getBaseApiUrl(),
});

// Request interceptor
apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    Object.entries(config.params || {})
      .filter(([_, value]) => !value)
      .forEach(([key]) => {
        delete config.params[key];
      });

    return config;
  },
  error => {
    // Handle request errors
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  response => {
    // You can modify the response data here (e.g., transform it)
    return response.data;
  },
  error => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Response error:', error.response.data);
      console.error('Status code:', error.response.status);
      if (error?.response?.status === 401) {
        toast("Not authenticated!");
        window.location.replace(window.location.origin + '/auth/login');
        localStorage.removeItem("token");
        localStorage.removeItem("company_id");
        localStorage.removeItem("account_id");
        localStorage.removeItem("chat_account_id");
        localStorage.removeItem("company");
        localStorage.removeItem("last_beat_time");
        console.error('error 401');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default apiClient;