import { useState } from "react";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { IconButton, Input, OutlinedInput, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCardsBlank, faList } from "@fortawesome/pro-regular-svg-icons";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { PayWallLayout } from "src/layouts/dashboard/paywall-layout";
import { articleMockedList } from "src/utils/constant/mock-data";
import { LandingPostCard } from "src/sections/landing/article/landing-article-card";
import { LandingBlogListView } from "src/sections/landing/article/blog-list-view";

const Page = () => {
  const [isCardsView, setIsCardsView] = useState(true);

  usePageView();

  return (
    <>
      <Seo title="Article" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xxl">
          <Stack spacing={1}>
            <Typography variant="h3">Articles</Typography>
          </Stack>

          <PayWallLayout>
            <Card
              elevation={16}
              sx={{
                alignItems: "center",
                borderRadius: 1,
                display: "flex",
                justifyContent: "space-between",
                mb: 8,
                mt: 6,
                px: 3,
                py: 2,
              }}
            >
              <Typography variant="subtitle1">
                Hello, Welcome
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  variant="contained"
                >
                  Public Page
                </Button>
                <Button
                  variant="contained"
                >
                  New Article
                </Button>
              </Stack>
            </Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="h4">Recent Articles</Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 2 }}
                  variant="body1"
                >
                  Discover the latest news, tips and user research insights.
                </Typography>
              </Stack>

              {isCardsView ? (
                <Tooltip title="Switch to List view">
                  <IconButton
                    onClick={() => {
                      setIsCardsView(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faList} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Switch to Cards view">
                  <IconButton
                    onClick={() => {
                      setIsCardsView(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faCardsBlank} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <Divider sx={{ my: 4 }} />
            {isCardsView ? (
              <Stack>
                <OutlinedInput
                  startAdornment={
                    <SvgIcon sx={{ mr: 1 }}>
                      <SearchMdIcon />
                    </SvgIcon>
                  }
                  placeholder="Enter a keyword"
                  type="search"
                />
                <Grid container spacing={4} sx={{ mt: 4 }}>
                  {articleMockedList.map((post) => (
                    <Grid
                      key={post.title}
                      xs={12}
                      md={6}
                    >
                      <LandingPostCard
                        authorAvatar={post.author.avatar}
                        authorName={post.author.name}
                        category={post.category}
                        cover={post.cover}
                        publishedAt={post.publishedAt}
                        readTime={post.readTime}
                        shortDescription={post.shortDescription}
                        title={post.title}
                        sx={{ height: '100%' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ) : (
              <Card>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <SvgIcon>
                    <SearchMdIcon />
                  </SvgIcon>
                  <Box sx={{ flexGrow: 1 }}>
                    <Input
                      disableUnderline
                      fullWidth
                      placeholder="Enter a keyword"
                    />
                  </Box>
                </Stack>
                <Divider />
                <LandingBlogListView />
              </Card>
            )}

            {isCardsView ? (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                spacing={1}
                sx={{
                  mt: 4,
                }}
              >
                <Button
                  startIcon={
                    <SvgIcon>
                      <ArrowLeftIcon />
                    </SvgIcon>
                  }
                >
                  Older posts
                </Button>
                <Button
                  endIcon={
                    <SvgIcon>
                      <ArrowRightIcon />
                    </SvgIcon>
                  }
                >
                  Newer
                </Button>
              </Stack>
            ) : null}
          </PayWallLayout>
        </Container>
      </Box>
    </>
  );
};

export default Page;
