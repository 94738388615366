import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { App } from 'src/app';
import { GlobalStyle } from './global/styles';

// Sentry.init({
//   dsn: "https://fbc6decf8b794cff93f4cabcb6fd93a9@o1181550.ingest.sentry.io/4505481870049280",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", /^https:\/\/tradevill\.online\/api/],
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//   ],
//   beforeSend: (event) => {
//     if (window.location.hostname === "localhost") {
//       return null
//     }
//     return event;
//   },
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <GlobalStyle />
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
