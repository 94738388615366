import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import { Settings } from "@mui/icons-material";

import AgentIcon from "src/icons/untitled-ui/duocolor/agent";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faBug, faFileArrowDown, faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { paths } from "src/paths";
import { tokens } from "src/locales/tokens";

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.wallets),
            path: paths.dashboard.wallets.index,
            icon: <FontAwesomeIcon icon={faWallet} />,
          },
          // {
          //   title: t(tokens.nav.analytics),
          //   path: paths.dashboard.analytics,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   )
          // },

          {
            title: t(tokens.nav.customers),
            path: paths.dashboard.customers.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.agents),
            path: paths.dashboard.agents,
            icon: (
              <SvgIcon fontSize="small">
                <AgentIcon />
              </SvgIcon>),
          },
          {
            title: t(tokens.nav.internalChat),
            path: paths.dashboard.internalChat,
            icon: (
              <SvgIcon fontSize="small">
                <MessageChatSquareIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.lead),
            path: "",
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.analytics),
                path: paths.dashboard.lead.analytic,
              },
              {
                title: t(tokens.nav.leads),
                path: paths.dashboard.lead.status.index,
              },
              {
                title: t(tokens.nav.affiliate),
                path: paths.dashboard.lead.affiliate.index,
              },
              {
                title: t(tokens.nav.brands),
                path: paths.dashboard.lead.brands.index,
              },
              {
                title: t(tokens.nav.injection),
                path: paths.dashboard.lead.injection.index,
              },
              {
                title: t(tokens.nav.offers),
                path: paths.dashboard.lead.offers.index,
              },
            ],
          },
          {
            title: t(tokens.nav.risk),
            path: "",
            icon: <ManageHistoryIcon fontSize="small" />,
            items: [
              {
                title: t(tokens.nav.positions),
                path: paths.dashboard.risk.positions,
              },
              {
                title: t(tokens.nav.transactions),
                path: paths.dashboard.risk.transactions,
              },
              {
                title: t(tokens.nav.walletTransactions),
                path: paths.dashboard.risk.wallet,
              },
            ],
          },
          {
            title: t(tokens.nav.logs),
            path: paths.dashboard.log.index,
            icon: <FontAwesomeIcon icon={faFileArrowDown} />,
          },
          // {
          //   title: t(tokens.nav.integration),
          //   path: '',
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <IntegrationInstructionsOutlinedIcon />
          //     </SvgIcon>
          //   ),
          //   items: [
          //     {
          //       title: t(tokens.nav.call),
          //       path: paths.dashboard.integration.callSystem.index,
          //     },
          //     {
          //       title: t(tokens.nav.paymentService),
          //       path: paths.dashboard.integration.paymentService.index,
          //     },
          //     {
          //       title: t(tokens.nav.marketing),
          //       path: paths.dashboard.integration.marketingAgencies.index,
          //     },
          //     {
          //       title: t(tokens.nav.lawFirms),
          //       path: paths.dashboard.integration.lawFirms.index,
          //     },
          //   ],
          // },
          {
            title: t(tokens.nav.payment),
            path: "",
            icon: (
              <SvgIcon fontSize="small">
                <VerifiedUserOutlinedIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.merchant),
                path: paths.dashboard.paymentAudit.merchant.index,
              },
              {
                title: t(tokens.nav.bankProvider),
                path: paths.dashboard.paymentAudit.bankProvider.index,
              },
              {
                title: t(tokens.nav.paymentType),
                path: paths.dashboard.paymentAudit.paymentType.index,
              },
              {
                title: t(tokens.nav.validationRules),
                path: paths.dashboard.paymentAudit.validationRules.index,
              },
              {
                title: t(tokens.nav.dataEntry),
                path: paths.dashboard.paymentAudit.dataEntry.index,
              },
              {
                title: t(tokens.nav.record),
                path: paths.dashboard.paymentAudit.record.index,
              },
            ],
          },
          {
            title: t(tokens.nav.calendar),
            path: paths.dashboard.calendar,
            icon: (
              <FontAwesomeIcon icon={faCalendar} />
            )
          },
          {
            title: t(tokens.nav.article),
            path: paths.dashboard.article.index,
            icon: (
              <SvgIcon fontSize="small">
                <LayoutAlt02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.settings),
            path: paths.dashboard.settings,
            icon: (
              <SvgIcon fontSize="small">
                <Settings />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.reports),
            path: paths.dashboard.reports,
            icon: (
              <FontAwesomeIcon icon={faBug} />
            )
          },
        ],
      },
    ];
  }, [t]);
};

export const useAffiliateSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.wallets),
            path: paths.dashboard.wallets.index,
            icon: <FontAwesomeIcon icon={faWallet} />,
          },
          // {
          //   title: t(tokens.nav.analytics),
          //   path: paths.dashboard.analytics,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   )
          // },

          {
            title: t(tokens.nav.customers),
            path: paths.dashboard.customers.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.agents),
            path: paths.dashboard.agents,
            icon: (
              <SvgIcon fontSize="small">
                <AgentIcon />
              </SvgIcon>),
          },
          {
            title: t(tokens.nav.internalChat),
            path: paths.dashboard.internalChat,
            icon: (
              <SvgIcon fontSize="small">
                <MessageChatSquareIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.analytics),
            path: paths.dashboard.lead.analytic,
            icon: (
              <SvgIcon fontSize="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z" />
                </svg>
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.leads),
            path: paths.dashboard.lead.status.index,
            icon: (
              <SvgIcon fontSize="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M384 64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64l0-384zM128 192a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 356.6c0-37.9 30.7-68.6 68.6-68.6h86.9c37.9 0 68.6 30.7 68.6 68.6c0 15.1-12.3 27.4-27.4 27.4H107.4C92.3 384 80 371.7 80 356.6z" />
                </svg>
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.affiliate),
            path: paths.dashboard.lead.affiliate.index,
            icon: (
              <SvgIcon fontSize="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 640 512"
                >
                  <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
                </svg>
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.brands),
            path: paths.dashboard.lead.brands.index,
            icon: (
              <SvgIcon fontSize="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 0c-35.3 0-64 28.7-64 64v64H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256 448c35.3 0 64-28.7 64-64V192 64c0-35.3-28.7-64-64-64H256zM64 304c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V304zm208 16c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272zm112-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zM80 192h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V208c0-8.8 7.2-16 16-16zM256 80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V80zM400 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H400c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16zM256 208V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zm144 16c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H400z" />
                </svg>
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.injection),
            path: paths.dashboard.lead.injection.index,
            icon: (
              <SvgIcon fontSize="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm32-128a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192z" />
                </svg>
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.offers),
            path: paths.dashboard.lead.offers.index,
            icon: (
              <SvgIcon fontSize="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm32-128a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192z" />
                </svg>
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.risk),
            path: "",
            icon: <ManageHistoryIcon fontSize="small" />,
            items: [
              {
                title: t(tokens.nav.positions),
                path: paths.dashboard.risk.positions,
              },
              {
                title: t(tokens.nav.transactions),
                path: paths.dashboard.risk.transactions,
              },
              {
                title: t(tokens.nav.walletTransactions),
                path: paths.dashboard.risk.wallet,
              },
            ],
          },
          // {
          //   title: t(tokens.nav.integration),
          //   path: '',
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <IntegrationInstructionsOutlinedIcon />
          //     </SvgIcon>
          //   ),
          //   items: [
          //     {
          //       title: t(tokens.nav.call),
          //       path: paths.dashboard.integration.callSystem.index,
          //     },
          //     {
          //       title: t(tokens.nav.paymentService),
          //       path: paths.dashboard.integration.paymentService.index,
          //     },
          //     {
          //       title: t(tokens.nav.marketing),
          //       path: paths.dashboard.integration.marketingAgencies.index,
          //     },
          //     {
          //       title: t(tokens.nav.lawFirms),
          //       path: paths.dashboard.integration.lawFirms.index,
          //     },
          //   ],
          // },
          {
            title: t(tokens.nav.payment),
            path: "",
            icon: (
              <SvgIcon fontSize="small">
                <VerifiedUserOutlinedIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.merchant),
                path: paths.dashboard.paymentAudit.merchant.index,
              },
              {
                title: t(tokens.nav.bankProvider),
                path: paths.dashboard.paymentAudit.bankProvider.index,
              },
              {
                title: t(tokens.nav.paymentType),
                path: paths.dashboard.paymentAudit.paymentType.index,
              },
              {
                title: t(tokens.nav.validationRules),
                path: paths.dashboard.paymentAudit.validationRules.index,
              },
              {
                title: t(tokens.nav.dataEntry),
                path: paths.dashboard.paymentAudit.dataEntry.index,
              },
              {
                title: t(tokens.nav.record),
                path: paths.dashboard.paymentAudit.record.index,
              },
            ],
          },
          {
            title: t(tokens.nav.article),
            path: paths.dashboard.article.index,
            icon: (
              <SvgIcon fontSize="small">
                <LayoutAlt02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.settings),
            path: paths.dashboard.settings,
            icon: (
              <SvgIcon fontSize="small">
                <Settings />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.reports),
            path: paths.dashboard.reports,
            icon: (
              <FontAwesomeIcon icon={faBug} />
            )
          },
          {
            title: t(tokens.nav.logs),
            path: paths.dashboard.log.index,
            icon: <FontAwesomeIcon icon={faFileArrowDown} />,
          },
        ],
      },
    ];
  }, [t]);
};
