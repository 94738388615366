import { createAsyncThunk } from "@reduxjs/toolkit";
import { internalChatApi } from "../api/internal-chat";

export const getInternalChat = createAsyncThunk('getInternalChat', async (data) => {
  const response = await internalChatApi.getInternalChats(data);
  return response?.conversations;
})

export const thunks = {
  getInternalChat,
};

