import { useEffect } from "react";
import Bell01Icon from "@untitled-ui/icons-react/build/esm/Bell01";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";

import { DeleteModal } from "src/components/customize/delete-modal";
import { EditReminderModal } from "./reminder-edit";
import { RemindersPopover } from "./reminders-popover";
import { usePopover } from "src/hooks/use-popover";
import { useReminders } from "src/hooks/overview/use-reminders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

export const RemindersButton = () => {
  const popover = usePopover();
  const {
    handleRemoveOne,
    handleMarkAllAsRead,
    reminders,
    unread,
    openDelete,
    openEdit,
    setOpenEdit,
    setOpenDelete,
    handleOpenDeleteReminder,
    handleOpenEditReminder,
    reminderToEdit,
    handleUpdateOne,
    handleRemindersGet,
  } = useReminders();

  useEffect(() => {
    if (popover.open) handleRemindersGet();
  }, [popover.open]);

  return (
    <>
      <Tooltip title="Reminders">
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}>
          <Badge
            color="error"
            badgeContent={unread}>
            <FontAwesomeIcon icon={faCalendar} color="#6C737F" />
          </Badge>
        </IconButton>
      </Tooltip>
      <RemindersPopover
        anchorEl={popover.anchorRef.current}
        reminders={reminders}
        onClose={popover.handleClose}
        onMarkAllAsRead={handleMarkAllAsRead}
        onRemoveOne={handleOpenDeleteReminder}
        onEditOne={handleOpenEditReminder}
        open={popover.open}
      />
      <DeleteModal
        isOpen={openDelete}
        setIsOpen={setOpenDelete}
        title="Are you sure you want to delete reminder?"
        onDelete={handleRemoveOne}
      />
      <EditReminderModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        reminder={reminderToEdit}
        onUpdateReminder={handleUpdateOne}
      />
    </>
  );
};
