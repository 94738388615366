import ReactLoading from "react-loading";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CustomModal from 'src/sections/components/modal';

export const DeleteModal = ({ isOpen, setIsOpen, onDelete = () => { }, title, description, isLoading = false, buttonTitle = "Delete" }) => {
  return (
    <CustomModal
      onClose={() => setIsOpen(false)}
      open={isOpen ?? false}>
      <Stack sx={{ textAlign: "center" }}
        style={{ maxWidth: "350px" }}
        spacing={2}>
        {!!title && <Typography
          id="modal-modal-title"
          sx={{ fontSize: 22, fontWeight: 'bold', pb: 2 }}
        >
          {title}
        </Typography>}
        {!!description && <Typography
          id="modal-modal-title"
          sx={{ fontSize: 18, pb: 2 }}>
          {description}
        </Typography>}
        <Stack
          sx={{ gap: 2 }}
          direction="row"
          justifyContent="center">
          <Button
            variant="contained"
            color='error'
            disabled={isLoading}
            sx={{ width: 80 }}
            onClick={() => {
              onDelete()
            }}>
            {isLoading ? (
              <ReactLoading
                type="bars"
                width={26}
                height={26}
                color={"white"}
              />
            ) : buttonTitle}
          </Button>
          <Button
            variant="outlined"
            color='error'
            onClick={() => setIsOpen(false)}>Cancel</Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
